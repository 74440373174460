import jsPDF from "jspdf";
import "jspdf-autotable";

const header = async (doc: any, params: any) => {
  doc.setFont("helvetica", "bold");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Nama Partner", 7, 10);
  doc.text(":", 60, 10);
  doc.text(params.name || "", 64, 10);

  const totalColor = params.isDiscount ? "#9e9e9e" : "#B82025";

  doc.text("Total Biaya", 7, 15);
  doc.text(":", 60, 15);
  doc.setTextColor(totalColor);
  doc.text(params.total, 64, 15);

  if (params.isDiscount) {
    doc.setDrawColor("#9e9e9e");
    doc.setLineWidth(0.2);
    const totalWidth = doc.getTextWidth(params.total);
    doc.line(64 + totalWidth, 14.3, 64, 14.3);
    doc.setTextColor("#B82025");
    doc.text(params.totalAfterDiscount, 66 + totalWidth, 15);
  }

  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal Dibuat", 7, 20);
  doc.text(":", 60, 20);
  doc.text(params.createdAt, 64, 20);
  doc.setTextColor("#9e9e9e");
  doc.text(params.createdBy, 64, 25);

  doc.addImage(params.logo, "PNG", 245, 9, 40, 10, "logo", "FAST", 0);
};

const drawCellData = (doc: any, data: any) => {
  if (data.row.index < 0) {
    return;
  }
  if (data.column.dataKey === "sttTotalAmount" && data.row.section === "body") {
    if (data.row.raw.sttIsPromo) {
      doc.setTextColor("#9e9e9e");
      const totalAmountWidth = doc.getTextWidth(data.row.raw.sttTotalAmount);
      doc.setDrawColor("#9e9e9e");
      doc.setLineWidth(0.2);
      doc.line(
        data.row.cells["sttTotalAmount"].x + totalAmountWidth + 2,
        data.row.cells["sttTotalAmount"].y +
          data.row.cells["sttTotalAmount"].contentHeight / 1.6,
        data.row.cells["sttTotalAmount"].x + 2,
        data.row.cells["sttTotalAmount"].y +
          data.row.cells["sttTotalAmount"].contentHeight / 1.6
      );
    } else {
      doc.setTextColor("#B82025");
    }
  }

  if (
    data.column.dataKey === "sttTotalAmountAfterDiscount" &&
    data.row.section === "body"
  ) {
    doc.setTextColor("#B82025");
  }
};

const Receipt = async (params: {
  name: string;
  total: string;
  isDiscount: boolean;
  totalAfterDiscount: string;
  createdAt: string;
  createdBy: string;
  data: Array<any>;
  logo: string;
}) => {
  const doc: any = new jsPDF("l", "mm", "a4", true);
  await header(doc, params);
  const mainColumns = [
    { header: "No", dataKey: "no" },
    { header: "No. STT", dataKey: "sttNo" },
    { header: "Total Koli", dataKey: "sttTotalPiece" },
    { header: "Total Berat", dataKey: "sttChargeableWeight" },
    { header: "Alamat Tujuan", dataKey: "sttRecipientAddress" },
    { header: "Pengirim", dataKey: "sttSenderName" },
    { header: "Penerima", dataKey: "sttRecipientName" },
    { header: "Asuransi", dataKey: "sttInsuranceName" },
    { header: "COD", dataKey: "sttIsCod" },
    { header: "Biaya COD", dataKey: "sttCodAmount" },
    { header: "Publish Rate", dataKey: "sttPublishRate" },
    { header: "Shipping Surcharge", dataKey: "sttShippingSurchageRate" },
    { header: "Forward Rate", dataKey: "sttForwardRate" },
    { header: "Lainnya", dataKey: "sttAmountOthers" },
    {
      header: `${
        params.isDiscount ? "Total Tarif Sebelum Diskon" : "Total Tarif"
      }`,
      dataKey: "sttTotalAmount"
    }
  ];

  const amountColumns = [
    {
      header: `${
        params.isDiscount ? "Total Tarif Setelah Diskon" : "Total Tarif"
      }`,
      dataKey: "sttTotalAmountAfterDiscount"
    }
  ];

  doc.autoTable({
    body: params.data,
    columns: params.isDiscount
      ? [...mainColumns, ...amountColumns]
      : mainColumns,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 33 : 0,
    margin: 7,
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "middle",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "middle",
      lineColor: "#9e9e9e",
      lineWidth: { top: 0.05, right: 0, bottom: 0.05, left: 0 },
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    columnStyles: {
      14: { cellWidth: 30 },
      15: { cellWidth: 30 }
    },
    willDrawCell: (data: any) => {
      drawCellData(doc, data);
    }
  });
  doc.setProperties({
    title: "Data Receipt"
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default Receipt;
