/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { BagListEntities, ListSttEntities } from "@/domain/entities/ListStt";
import { Pagination } from "@/domain/entities/Pagination";
import { ListSttPresenter } from "../presenters/ListSttPresenter";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";

export interface ListSttState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  isError: boolean;
  isErrorCause: string;
  search: string;
  messageSuccess: string;
  showModalManualBooking: boolean;
  showModalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "bulk_print" })
class ListSttStore extends VuexModule implements ListSttState {
  public isLoading = false;
  public isLoadingDetail = false;
  public showModalManualBooking = false;
  public showModalSuccess = false;
  public isError = false;
  public isErrorCause = "";
  public search = "";
  public sttList = new ListSttEntities(new Pagination(1, 10), []);
  public bagList = new BagListEntities([]);
  public messageSuccess = "";
  public isTableShow = false;
  public errorMessageList: any = "";
  public sttListValue: Array<any> = [];
  public resiListData: Array<PrintDataEntitas> = [];

  @Mutation
  public setResiListData(data: PrintDataEntitas[]) {
    this.resiListData = data;
  }

  @Action
  public getListStt(params: {
    page: number;
    limit: number;
    destinationCityId: number;
    userType: string;
    bookedBy: string;
    fromDate: string;
    endDate: string;
    sttNumber: string;
    bookedType: string;
  }) {
    this.setIsLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ListSttPresenter);
    presenter
      .getSttList(
        params.page,
        params.limit,
        params.destinationCityId,
        params.userType,
        params.bookedBy,
        params.fromDate,
        params.endDate,
        params.sttNumber,
        params.bookedType
      )
      .then(async (res: ListSttEntities) => {
        const list: Array<any> = [];
        for (const [index, item] of res.data.entries()) {
          await BookingController.generatePrintThermal({
            stt: item.sttId,
            accountType:
              AccountController.accountData.account_type === "partner"
                ? AccountController.accountData.account_type_detail.type
                : AccountController.accountData.account_type
          })
            .then(data => {
              list.push(data);
              if (index === res.data.length - 1) {
                this.setResiListData(list);
                this.setShowTable(true);
                this.setSttList(res);
                this.setError(false);
              }
            })
            .catch((error: any) => {
              this.setError(true);
              this.setShowTable(false);
              MainAppController.showErrorMessage(
                parsingErrorResponse(error, "Penarikan Data Gagal", () => {
                  MainAppController.closeErrorMessage();
                  this.getListStt(params);
                })
              );
            });
        }
      })
      .catch(err => {
        if (!err.response) {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Penarikan Data Gagal", () => {
              MainAppController.closeErrorMessage();
              this.getListStt(params);
            })
          );
        } else {
          this.setErrorMessage(err.response?.data?.message?.id || "");
          this.setError(true);
          this.setShowTable(false);
        }
      })
      .finally(() => {
        this.setIsLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getListBag(params: { bagNumber: string }) {
    this.setIsLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ListSttPresenter);
    presenter
      .getBagList(params.bagNumber)
      .then(async (res: BagListEntities) => {
        this.setShowTable(true);
        this.setBagList(res);
      })
      .catch(err => {
        if (!err.response) {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Penarikan Data Gagal", () => {
              MainAppController.closeErrorMessage();
              this.getListBag(params);
            })
          );
        } else {
          this.setErrorMessage(err.response?.data?.message?.id || "");
          this.setError(true);
          this.setShowTable(false);
        }
      })
      .finally(() => {
        this.setIsLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Mutation
  private setSttList(data: ListSttEntities) {
    this.sttList = data;
  }

  @Mutation
  private setBagList(data: BagListEntities) {
    this.bagList = data;
  }

  @Action
  public resetSttList() {
    this.setResetSttList();
    this.setError(false);
  }

  @Mutation
  public setResetSttList() {
    this.sttList = new ListSttEntities(new Pagination(1, 10), []);
  }

  @Mutation
  public setShowTable(value: boolean) {
    this.isTableShow = value;
  }

  @Mutation
  public setLoading(key: boolean) {
    this.isLoading = key;
  }

  @Mutation
  private setErrorMessage(key: string) {
    this.errorMessageList = key.split(":").pop();
  }

  @Mutation
  public resetErrorMessage(key: string) {
    this.errorMessageList = "";
  }

  @Mutation
  public setSttListValue(value: any) {
    this.sttListValue.push(value);
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.isErrorCause = val;
  }

  @Mutation
  public setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    // to do logic search
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setShowModalManualBooking(value: boolean) {
    this.showModalManualBooking = value;
  }

  @Mutation
  public setShowModalSuccess(value: boolean) {
    this.showModalSuccess = value;
  }

  @Mutation
  public resetBagList() {
    this.bagList = new BagListEntities([]);
  }

  @Mutation
  public resetResiListData() {
    this.resiListData = [];
  }
}

export const ListSttController = getModule(ListSttStore);
