<template>
  <div
    class="bg-white flex flex-col text-12px xxl:text-14px overflow-auto h-screen w-full pt-8"
  >
    <Breadcrumb />
    <Header :currentRoutes="getCurrentRoute" />
    <DataWrapper
      label="Daftar No. STT"
      class="w-full pt-6 text-12px xxl:text-14px"
    >
      <div class="text-gray-lp-500 pt-2">
        Jumlah maksimum yang dapat diproses adalah 50 STT.
      </div>
      {{ setErrorList }}
      {{ clearSttListValue() }}
      <div class="w-full">
        <div class="pt-4 w-3/4 inline-block relative">
          <multiselect
            v-model="sttListValue"
            placeholder="Ketik atau pilih No. STT"
            tag-placeholder="Press enter to add No. STT"
            label="name"
            track-by="id"
            :maxLength="50"
            :options="sttManualList"
            :multiple="true"
            :searchable="true"
            :taggable="true"
            :preserve-search="true"
            open-direction="bottom"
            isTextArea
            @keyup="onSearch($event)"
            :isError="isError"
          />
          <div
            class="absolute right-0 bottom-0 mr-2 mb-2 text-gray-lp-600 text-12px xxl:text-14px"
            style="margin-top: 250px"
          >
            {{ sttListValue.length }}/50
          </div>
        </div>
        <div
          class="mt-4 text-red-lp-100 text-12px xxl:text-14px"
          v-show="isError"
        >
          {{ errorMessageLength }}/{{ sttListValue.length }} Data stt tidak
          ditemukan
        </div>
      </div>
      <div class="pt-4 flex flex-row">
        <button-fill
          custom="bg-white px-12 border border-red-lp-100 text-white mr-4"
          title="Buat Tagihan"
          :iconLeft="iconShowTable"
          :isDisabled="sttListValue.length === 0"
          @click="onGenerate"
        />
        <button-outline
          v-show="sttListValue.length !== 0"
          custom="bg-white px-12 border border-red-lp-100 text-red-lp-100 mr-4"
          title="Hapus Semua"
          @click="deleteAll"
        />
      </div>
    </DataWrapper>
    <div class="border-t my-6 border-gray-lp-400" />
    <DataWrapper
      v-if="isTableShow"
      label="Informasi Umum"
      class="w-full pt-2 text-20px h-screen"
    >
      <div class="flex flex-col justify-between mt-2">
        <DataWrapper
          v-if="roleAccount !== 'internal'"
          label="Nama Partner"
          class="text-12px xxl:text-14px text-black-lp-100"
        >
          <div class="mt-2 text-black-lp-300 text-12px xxl:text-14px">
            {{ dataProfile.account_type_detail.company_name }}
          </div>
        </DataWrapper>
        <div class="flex flex-row py-4 w-full justify-between items-start">
          <div class="flex flex-row gap-x-8 py-4">
            <DataWrapper
              label="Tanggal Dibuat"
              class="text-12px xxl:text-14px text-black-lp-100"
            >
              <div class="mt-2 text-black-lp-300 text-12px xxl:text-14px">
                {{ createdAt }}
              </div>
              <div class="text-gray-lp-500 text-12px xxl:text-14px">
                {{ dataProfile.name }}
              </div>
            </DataWrapper>
            <DataWrapper
              label="Total Tarif"
              class="text-12px xxl:text-14px text-black-lp-100"
            >
              <div
                :class="
                  `${
                    totalAmountAfterDiscount.status
                      ? 'text-gray-lp-600 line-through'
                      : 'text-red-lp-100'
                  } mt-2`
                "
              >
                {{ formatPriceRP(totalAmount) }}
              </div>
              <div
                v-if="totalAmountAfterDiscount.status"
                class="text-red-lp-100"
              >
                {{ totalAmountAfterDiscount.nominal }}
              </div>
            </DataWrapper>
            <DataWrapper
              label="Total Tagihan"
              class="ml-8 text-12px xxl:text-14px text-black-lp-100"
            >
              <div
                :class="
                  `${
                    totalPaymentAfterDiscount.status
                      ? 'text-gray-lp-600 line-through'
                      : 'text-red-lp-100'
                  } mt-2`
                "
              >
                {{ formatPriceRP(totalPayment) }}
              </div>
              <div
                v-if="totalPaymentAfterDiscount.status"
                class="text-red-lp-100"
              >
                {{ totalPaymentAfterDiscount.nominal }}
              </div>
            </DataWrapper>
          </div>
          <div class="flex flex-row gap-x-4 py-4 items-start">
            <button-fill
              v-if="isShowButtonPayment"
              class="self-center"
              custom="bg-white px-4 border border-red-lp-100 text-white"
              iconLeft="payment-button"
              title="Bayar Tagihan"
              @click="payInvoice"
              :isDisabled="!!iconShowTable || !payAbleStt.length"
            />
            <button-fill
              class="self-center"
              custom="bg-white px-4 border border-red-lp-100 text-white"
              iconLeft="document-download-outline-white"
              title="Print Basic/Save to PDF"
              :isDisabled="!!iconShowTable"
              @click="printDoc()"
            />
            <button-outline
              v-if="isSendEmailAccessible"
              class="self-center"
              custom="bg-white px-4 border border-red-lp-100 text-red-lp-100"
              iconLeft="mail"
              title="Send"
              @click="openPopUpSendEmail"
            />
            <div class="hidden">
              <img alt="barcode" id="barcode" />
              <img
                id="logo"
                src="@/app/ui/assets/images/logo-lion-parcel-3x.png"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full relative" v-if="payAbleStt.length">
        <DataWrapper
          v-if="isTableShow"
          label="Tertagih"
          class="w-full pt-2 text-20px border-t"
        >
          <div class="flex flex-row py-4">
            <DataWrapper
              v-for="n in 2"
              :key="n"
              :label="`Total ${n > 1 ? 'Tagihan' : 'Tariff'}`"
              :class="
                `text-12px xxl:text-14px text-black-lp-100 ${
                  n > 1 ? 'ml-20' : ''
                }`
              "
            >
              <div
                :class="
                  `${
                    totalPaymentAfterDiscount.status
                      ? 'text-gray-lp-600 line-through'
                      : 'text-red-lp-100'
                  } mt-2`
                "
              >
                {{ formatPriceRP(totalPayment) }}
              </div>
              <div
                v-if="totalPaymentAfterDiscount.status"
                class="text-red-lp-100"
              >
                {{ totalPaymentAfterDiscount.nominal }}
              </div>
            </DataWrapper>
          </div>
        </DataWrapper>
        <TableV2
          :columns="columns"
          :data="payAbleStt"
          isRightFreeze
          :totalColumnFreeze="1"
          v-model:pagination="pagination"
          hidePagination
        />
      </div>
      <div class="w-full relative" v-if="unPayAbleStt.length">
        <DataWrapper
          v-if="isTableShow"
          label="Tagihan Dibayar Otomatis (Cashless)"
          class="w-full pt-2 text-20px border-t"
        >
          <div class="flex flex-row py-4">
            <DataWrapper
              label="Total Tarif"
              class="text-12px xxl:text-14px text-black-lp-100"
            >
              <div
                :class="
                  `${
                    totalPaymentSttClientAfterDiscount < totalPaymentSttClient
                      ? 'text-gray-lp-600 line-through'
                      : 'text-red-lp-100'
                  } mt-2`
                "
              >
                {{ formatPriceRP(totalPaymentSttClient) }}
              </div>
              <div
                v-if="
                  totalPaymentSttClientAfterDiscount < totalPaymentSttClient
                "
                class="text-red-lp-100"
              >
                {{ formatPriceRP(totalPaymentSttClientAfterDiscount) }}
              </div>
            </DataWrapper>
            <DataWrapper
              label="Total Tagihan"
              class="ml-20 text-12px xxl:text-14px text-black-lp-100"
            >
              <div class="text-red-lp-100 mt-2">Rp 0</div>
            </DataWrapper>
          </div>
        </DataWrapper>
        <TableV2
          :columns="columns"
          :data="unPayAbleStt"
          isRightFreeze
          :totalColumnFreeze="1"
          v-model:pagination="pagination"
          hidePagination
        />
      </div>
    </DataWrapper>
  </div>
  <ModalSendEmail
    v-if="popUpSendEmail"
    v-model="popUpSendEmail"
    :totalAmount="totalAmount"
    :isDiscount="totalAmountAfterDiscount.status"
    :totalAmountAfterDiscount="totalAmountAfterDiscount.pureNominal"
    :listData="listData"
  />
  <DialogPayment
    @successPayment="onCloseSuccess"
    billType="tagihan"
    textHeader="Cetak Tagihan"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import {
  checkEnablePaymentQris,
  convertArrayToString,
  convertDecimalWithComma,
  ellipsisString,
  formatDate,
  formatPriceIDR,
  formatPriceRP,
  firstCapitalize
} from "@/app/infrastructures/misc/Utils";
import Multiselect from "@/app/ui/components/multiselect-search-tag/index.vue";
import Receipt from "./modules/receipt";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ListSttController } from "@/app/ui/controllers/ListSttController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import DialogPayment from "@/app/ui/components/dialog-payment/index.vue";
import { PaymentMethod } from "@/domain/entities/Payment";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

const ModalSendEmail = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/shipment/print-receipt/component/modal-send-email.vue"
    )
});

@Options({
  components: {
    ModalSendEmail,
    EmptyState,
    Multiselect,
    DialogPayment
  }
})
export default class PrintReceipt extends Vue {
  emptyTableData = [];

  mounted() {
    this.onRequest();
    ListSttController.setError(false);
    ListSttController.setResetSttList();
    ListSttController.setShowTable(false);
    this.handleSttFromQuery();
  }

  get getCurrentRoute() {
    return this.$route.meta.name;
  }

  // Generate Function
  get isTableShow() {
    return ListSttController.isTableShow;
  }
  onGenerate() {
    this.cacheSttListValue = this.sttListValue;
    this.getListSttTable();
    GTMCommonEvent("cetak_tagihan_buat_tagihan");
  }

  // get list table stt
  getListSttTable() {
    ListSttController.getListStt({
      page: 1,
      limit: this.sttListValue.length,
      destinationCityId: 0,
      userType: "",
      bookedBy: "",
      bookedType: "",
      fromDate: "",
      sttNumber: convertArrayToString(this.sttListValue, "name"),
      endDate: ""
    });
  }

  get iconShowTable() {
    let isExist = true;
    for (const stt of this.sttListValue) {
      const isAny = this.cacheSttListValue.find(e => e.id === stt.id);
      if (!isAny) {
        isExist = false;
        break;
      }
    }
    return this.cacheSttListValue.length > 0 &&
      this.sttListValue.length > 0 &&
      (!isExist || this.sttListValue.length !== this.cacheSttListValue.length)
      ? "refresh-white"
      : "";
  }

  // Table
  get listData() {
    return ListSttController.sttList.data;
  }
  get payAbleStt() {
    return ListSttController.sttList.data.filter(
      item => item.sttNo.startsWith("11") || item.sttNo.startsWith("10")
    );
  }
  get unPayAbleStt() {
    return ListSttController.sttList.data.filter(
      item => !(item.sttNo.startsWith("11") || item.sttNo.startsWith("10"))
    );
  }
  columns = [
    {
      name: "No",
      styleHead: "w-10 text-left",
      styleBody: "text-center",
      render: (item, index) => {
        return `<div class='text-left text-black-lp-300'>${index +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: item => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Total Koli",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: item => {
        return `<div class='flex text-black-lp-300'>${item.sttTotalPiece}</div>`;
      }
    },
    {
      name: "Total Berat",
      styleHead: "w-32 text-left",
      render: item => {
        return `<div class='flex text-black-lp-300'>${convertDecimalWithComma(
          item.sttChargeableWeight,
          2
        )} Kg</div>`;
      }
    },
    {
      name: "Alamat Tujuan",
      styleHead: "w-64 text-left",
      render: item => {
        return `<div class="overflow-ellipsis text-black-lp-300">${ellipsisString(
          item.sttRecipientAddress,
          30
        )}</div>`;
      }
    },
    {
      name: "Pengirim",
      styleHead: "w-44 text-left",
      render: item => {
        return `<div class='flex text-black-lp-300'>${ellipsisString(
          item.sttSenderName,
          45
        )}</div>`;
      }
    },
    {
      name: "Penerima",
      styleHead: "w-68 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${ellipsisString(item.sttRecipientName, 78)}
                </div>`;
      }
    },
    {
      name: "Asuransi",
      styleHead: "w-24 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex capitalize">
                      ${item.sttInsuranceName}
                </div>`;
      }
    },
    {
      name: "COD",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${item.sttIsCod ? "Ya" : "Tidak"}
                </div>`;
      }
    },
    {
      name: "Biaya COD",
      styleHead: "w-40 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${formatPriceIDR(item.sttCodAmount)}
                </div>`;
      }
    },
    {
      name: "Publish Rate",
      styleHead: "w-40 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${formatPriceIDR(item.sttPublishRate)}
                </div>`;
      }
    },
    {
      name: "Shipping Surcharge",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${formatPriceIDR(item.sttShippingSurchageRate)}
                </div>`;
      }
    },
    {
      name: "Forward Rate",
      styleHead: "w-40 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${this.amountForwardRate(item)}
                </div>`;
      }
    },
    {
      name: "Lainnya",
      styleHead: "w-40 text-left",
      render: item => {
        return `<div class="text-black-lp-300 flex">
                      ${this.amountOthers(item)}
                </div>`;
      }
    },
    {
      name: "Total Tarif",
      styleHead: "w-40 text-left",
      render: item => {
        return `<div class='flex flex-col'>
        ${this.totalTariff(item)}
                </div>`;
      }
    }
  ];

  pagination = {
    page: 1,
    limit: 50
  };

  // handling
  get isLoading() {
    return ListSttController.isLoading;
  }
  get isError() {
    return ListSttController.isError;
  }
  get errorCause() {
    return ListSttController.isErrorCause;
  }
  get errorMessageLength() {
    return ListSttController.errorMessageList.split(",").length;
  }
  get errorMessageList() {
    return ListSttController.errorMessageList;
  }

  totalTariff(item) {
    return item.sttIsPromo
      ? `<div class="text-gray-lp-600 flex font-semibold">
                      <s>${formatPriceIDR(
                        item.sttTotalAmountBeforeDiscount
                      )}</s>
                </div>
            <div class="text-red-lp-100 flex font-semibold">
                      ${formatPriceIDR(item.sttTotalAmountAfterDiscount)}
                </div>`
      : `<div class="text-red-lp-100 flex font-semibold">
                      ${formatPriceIDR(item.sttTotalAmount)}
                </div>`;
  }

  // Fetch No. STT
  sttListValue = [];
  cacheSttListValue = [];

  get setErrorList() {
    const error = ListSttController.errorMessageList;
    this.sttListValue = this.sttListValue.map(item => {
      return { ...item, isError: error.includes(item.name) };
    });
    return "";
  }

  clearSttListValue() {
    if (this.sttListValue.length === 0) {
      ListSttController.resetSttList();
      ListSttController.setShowTable(false);
    }
  }

  onRequest() {
    ShipmentBookingController.fetchShipmentBooking();
  }

  get sttManualList() {
    return ShipmentBookingController.shipmentBookingData.data.map(e => ({
      name: e.sttNo,
      id: e.sttId
    }));
  }

  get isLoadingSttManual() {
    return ShipmentBookingController.isLoading;
  }

  onSearch(e) {
    ShipmentBookingController.searchAction(e.target.value);
  }

  deleteAll() {
    this.sttListValue = [];
    this.cacheSttNo = [];
    ListSttController.resetSttList();
    ListSttController.setShowTable(false);
  }

  // Print Doc PDF
  get dataProfile() {
    return AccountController.accountData;
  }
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
  async printDoc() {
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    new Promise(function(resolve) {
      MainAppController.showLoading();
      setTimeout(resolve.bind(null, null), 1);
    }).then(() => {
      Receipt({
        name: this.dataProfile.account_type_detail.company_name,
        total: formatPriceIDR(this.totalAmount),
        isDiscount: this.totalAmountAfterDiscount.status,
        totalAfterDiscount: this.totalAmountAfterDiscount.nominal,
        createdAt: this.createdAt,
        createdBy: this.dataProfile.name,
        data: this.listDataPrintPDF,
        logo: logo.default
      });
      MainAppController.closeLoading();
    });

    GTMCommonEvent("cetak_tagihan_print_basic");
  }

  get listDataPrintPDF() {
    return this.listData.map((item, index) => ({
      no: index + 1,
      sttNo: item.sttNo,
      sttTotalPiece: item.sttTotalPiece,
      sttRecipientAddress: ellipsisString(item.sttRecipientAddress, 30),
      sttDestination: `${item.sttDestinationDistrictName}, ${item.sttDestinationCity} - ${item.sttDestinationCityCode}`,
      sttRecipientPhone: ellipsisString(item.sttRecipientPhone, 30),
      sttSenderName: ellipsisString(item.sttSenderName, 20),
      sttRecipientName: ellipsisString(item.sttRecipientName, 20),
      sttChargeableWeight: item.sttChargeableWeight + " Kg",
      sttIsInsurance: item.sttIsInsurance ? "Ya" : "Tidak",
      sttInsuranceName: firstCapitalize(item.sttInsuranceName),
      sttIsCod: item.sttIsCod ? "Ya" : "Tidak",
      sttShippingSurchageRate: formatPriceIDR(item.sttShippingSurchageRate),
      sttTotalAmount: formatPriceIDR(item.sttTotalAmountBeforeDiscount),
      sttIsPromo: item.sttIsPromo,
      sttTotalAmountAfterDiscount: item.sttIsPromo
        ? formatPriceIDR(item.sttTotalAmountAfterDiscount)
        : "",
      sttCodAmount: formatPriceIDR(item.sttCodAmount),
      sttPublishRate: formatPriceIDR(item.sttPublishRate),
      sttForwardRate: this.amountForwardRate(item),
      sttAmountOthers: this.amountOthers(item)
    }));
  }

  amountOthers(data) {
    return formatPriceIDR(
      data.sttCommoditySurchargeRate +
        data.sttDocumentSurchargeRate +
        data.sttHeavyweightSurchargeRate +
        data.sttTotalInsurance +
        data.sttWoodpackingRate
    );
  }

  amountForwardRate(data) {
    return formatPriceIDR(data.sttForwardRate);
  }

  get totalAmount() {
    return this.totalPayment + this.totalPaymentSttClient;
  }

  get totalAmountDiscount() {
    return this.listData.reduce((a, b) => a + b.sttTotalDiscount, 0);
  }

  get paymentPayableAfterDiscount() {
    return this.payAbleStt.reduce(
      (a, b) => a + b.sttTotalAmountAfterDiscount,
      0
    );
  }

  get totalAmountAfterDiscount() {
    const total =
      this.paymentPayableAfterDiscount +
      this.totalPaymentSttClientAfterDiscount;
    return {
      status: this.totalAmountDiscount > 0,
      pureNominal: total,
      nominal: formatPriceRP(total)
    };
  }

  get totalPayment() {
    return this.payAbleStt.reduce(
      (a, b) => a + b.sttTotalAmountBeforeDiscount,
      0
    );
  }

  get totalPaymentDiscount() {
    return this.payAbleStt.reduce((a, b) => a + b.sttTotalDiscount, 0);
  }

  get totalPaymentAfterDiscount() {
    const total = this.payAbleStt.reduce(
      (a, b) => a + b.sttTotalAmountAfterDiscount,
      0
    );
    return {
      status: this.totalPaymentDiscount > 0,
      nominal: formatPriceRP(total)
    };
  }

  get totalPaymentSttClient() {
    return this.unPayAbleStt.reduce(
      (a, b) => a + b.sttTotalAmountBeforeDiscount,
      0
    );
  }
  get totalPaymentSttClientAfterDiscount() {
    return this.unPayAbleStt.reduce(
      (a, b) => a + b.sttTotalAmountAfterDiscount,
      0
    );
  }

  get createdAt() {
    return formatDate(new Date());
  }

  // stt from query
  get sttFromQuery() {
    const query = this.$route.query.q;
    if (!query) {
      return [];
    }
    return Array.from(new Set(query.split(",")));
  }
  handleSttFromQuery() {
    if (this.sttFromQuery.length === 0) {
      return;
    }
    for (const stt of this.sttFromQuery) {
      this.sttListValue.push({
        name: stt,
        id: stt,
        isError: false
      });
    }
    this.onGenerate();
  }

  // send email
  get isSendEmailAccessible() {
    return this.roleAccount === "internal" || this.roleAccount === "pos";
  }
  popUpSendEmail = false;
  openPopUpSendEmail() {
    this.popUpSendEmail = true;
    GTMCommonEvent("cetak_tagihan_sent");
  }

  payInvoice() {
    dataLayer("tagihan_bayar_tagihan_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    this.initPayment();
  }

  initPayment() {
    PaymentController.changePaymentMethod(new PaymentMethod());
    PaymentController.setBillAmount(this.totalPaymentQr);
    PaymentController.setSttNumber(this.payAbleStt.map(e => e.sttNo).join(","));
    PaymentController.toggleDialogPayment(true);
  }

  onCloseSuccess() {
    ListSttController.setError(false);
    ListSttController.setResetSttList();
    ListSttController.setShowTable(false);
    this.sttListValue = [];
  }

  get isShowButtonPayment() {
    return this.roleAccount === "pos" && checkEnablePaymentQris("tagihan");
  }

  get totalPaymentQr() {
    return this.payAbleStt.reduce((a, b) => {
      return a + b.sttTotalAmount;
    }, 0);
  }

  formatPriceRP(val) {
    return formatPriceRP(val);
  }
}
</script>
